import React from "react";
import { useRouter } from "next/navigation";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@acme/auth";
import { Button } from "@acme/ui/button";
import HomeLayout from "~/layouts/HomeLayout";
const AuthPage: React.FC = () => {
  const {
    userId
  } = useAuth();
  const router = useRouter();
  return <HomeLayout>
      <div className="flex animate-fade flex-col gap-4 duration-500 max-md:px-8">
        <h1 className="text-4xl font-semibold">Welcome to Vital</h1>
        <div>
          <h3>Nutrition, fitness, mindfulness and recovery protocols.</h3>
          <h2 className="italic">Inspired by experts - all in one platform.</h2>
        </div>
        <div className="flex gap-3 py-6">
          {!userId && <Button data-test="sign-in-button" size="fun" variant={"outline"} className="flex basis-2/5 whitespace-nowrap" onClick={() => {
          router.push("/sign-in");
        }}>
              Sign in
            </Button>}
          <Button data-test="sign-up-button" size={"fun"} onClick={() => {
          router.push("/sign-up");
        }} color="brand" className="flex basis-3/5 whitespace-nowrap">
            Get started <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </HomeLayout>;
};
export default AuthPage;