import type { ReactNode } from "react";
import React from "react";
import Link from "next/link";

import Logotype from "~/components/core/Logotype";
import { ThemedImage } from "~/components/theme/ThemedImage";

interface MainLayoutProps {
  children: ReactNode;
}

const HomeLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex min-h-[100dvh] flex-col ">
      <div className="flex flex-grow items-center justify-center">
        <div className="flex-1">
          <ThemedImage
            src="jogging-v.svg"
            alt="illustration of person jogging on the beach on sunny day with city in background and vital routines logo tank top"
            fill
            className="-z-10 object-cover object-center md:object-right"
          />
          <div className="absolute left-10 top-10 z-10 hidden md:block">
            <Link href="/">
              <Logotype theme="light" width={64} height={10} />
            </Link>
          </div>
        </div>
        <div className="relative flex h-dynamic-screen w-full flex-col items-center md:basis-1/2">
          <div className="absolute inset-0 -z-10 bg-background/90"></div>
          <div className="my-auto w-full max-w-xl overflow-y-auto overflow-x-hidden py-8 scrollbar-none md:p-16 ">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLayout;
