import { cn } from "~/lib/utils";
import { NextImage } from "../core/NextImageCore";
import type { NextImageProps } from "../core/NextImageCore";

export type ThemedImageName =
  | "feedback-hint.png"
  | "ufo.png"
  | "logo.svg"
  | "logo.png"
  | "calendar.png"
  | "logotype.svg"
  | "flag.svg"
  | "plank.svg"
  | "scientist.svg"
  | "app-preview.png"
  | "hike.svg"
  | "activities-placeholder-chart.png"
  | "adherence-placeholder-chart.png"
  | "protocols-placeholder.png"
  | "jogging-v.svg"
  | "accountable.png"
  | "superpower.png"
  | "all-in-one.png"
  | "research-based.png"
  | "not-found.svg";

type Props = NextImageProps & {
  src: ThemedImageName;
  theme?: "dark" | "light";
};

export const ThemedImage = (props: Props) => {
  const lightSrc = `/themedImages/light/${props.src}`;
  const darkSrc = `/themedImages/dark/${props.src}`;

  if (props.theme === "light") {
    return <NextImage {...props} src={lightSrc} />;
  } else if (props.theme === "dark") {
    return <NextImage {...props} src={darkSrc} />;
  }

  return (
    <>
      <NextImage
        draggable={false}
        {...props}
        src={lightSrc}
        className={cn("block dark:hidden", props.className)}
      />
      <NextImage
        draggable={false}
        {...props}
        src={darkSrc}
        className={cn("hidden dark:block", props.className)}
      />
    </>
  );
};
